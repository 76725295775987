import {
  Button,
  Center,
  FileInput,
  List,
  Mark,
  Radio,
  Stack,
  Text,
  TextInput,
  Textarea,
  Title,
} from '@mantine/core';
import { DatePickerInput } from '@mantine/dates';
import { isNotEmpty, useForm } from '@mantine/form';
import { notifications } from '@mantine/notifications';
import {
  IconCalendar,
  IconCheck,
  IconFileUpload,
  IconX,
} from '@tabler/icons-react';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import apiHelper from '../../utils/api';
import useLoader from '../common/hooks/useLoader.hook';

const RenewalForm = () => {
  const navigate = useNavigate();
  const [emailFile, setEmailFile] = useState<File | null>(null);
  const { isLoading, showLoader, hideLoader } = useLoader();

  const form = useForm({
    initialValues: {
      school: '',
      dueDate: dayjs().add(7, 'day'),
      subject:
        'Action Required: Renewal of School Bus Service for {{ childName }}',
      header: 'Renewal',
      content:
        "Please complete the 2025 School Bus Service Renewal Application Form by clicking the button below or following the link below. We seek your assistance to complete the form by {{ dueDate }}.\n\nBased on the 2024 transport address and trip arrangement, the adjusted bus fee for 2025 is {{ fare }}. If there are any changes to your 2024 transport arrangement, we will provide a revised 2025 fee.\n\nTo confirm and secure a seat for your child, a one-month retainer fee will be collected upon renewal registration. This retainer fee will be used to offset the final month's bus fee in 2025. The renewal process is only complete after we receive the retainer fee.",
    },
    validate: {
      school: isNotEmpty('Please select a school'),
      subject: isNotEmpty('Please enter a subject'),
      header: isNotEmpty('Please enter a header'),
    },
    validateInputOnBlur: true,
  });

  const handleSubmit = async () => {
    showLoader();
    try {
      if (emailFile == null) throw Error('Please upload an excel file');

      // prepare data for request
      const formData = new FormData();
      formData.append('file', emailFile, emailFile.name);
      formData.append('form', JSON.stringify(form.values));

      const data = await apiHelper.generateRenewals(formData);
      navigate(`/portal/renewal/log/${data.renewalGroupId}`);
      notifications.show({
        title: 'Renewals Successfully Generated!',
        message:
          'You have been redirected to the renewals logs page. Please verify that the information is correct before sending the emails.',
        icon: <IconCheck />,
        withCloseButton: true,
      });

      form.reset();
    } catch (err) {
      console.error(err);
      notifications.show({
        title: 'An error has ocurred!',
        message: err.message ?? 'Please try again later',
        color: 'red',
        icon: <IconX />,
        autoClose: false,
      });
    } finally {
      hideLoader();
      setEmailFile(null);
    }
  };

  return (
    <>
      <Title>Generate Renewal</Title>
      <Text fz="sm">
        Please note the following steps to successfully generate renewals:
      </Text>
      <List type="ordered" fz="sm">
        <List.Item>
          The excel file you are uploading must have a <Mark>Buslist</Mark>{' '}
          sheet.
        </List.Item>
        <List.Item>
          After filling in this form, the excel file containing the bus list
          will be processed by the system{' '}
          <Mark>but no emails will be sent.</Mark>
        </List.Item>
        <List.Item>
          If the file is successfully processed, you will be redirected to the
          renewal logs page where you can verify that the information has been
          correctly processed.
        </List.Item>
        <List.Item fw={500}>
          If the information is correct,{' '}
          <Mark>please proceed to send the emails to the parents.</Mark>
        </List.Item>
      </List>
      <Stack
        pos={'relative'}
        spacing={'10px'}
        sx={(theme) => ({
          backgroundColor:
            theme.colorScheme === 'dark'
              ? theme.colors.dark[8]
              : theme.colors.gray[0],
        })}
      >
        <form onSubmit={form.onSubmit(handleSubmit)}>
          <Radio.Group
            py="lg"
            name="schoolSelect"
            label="School"
            error="Please select school"
            required={true}
            {...form.getInputProps('school')}
          >
            <Radio value="SJI" label="SJI Junior" />
            <Radio value="SAJS" label="St Andrew's" />
            <Radio value="SMPS" label="St Margaret's " />
            <Radio value="KHS" label="Kong Hwa School" />
          </Radio.Group>

          <DatePickerInput
            icon={<IconCalendar size="20" stroke={1.5} />}
            label="Renewal Deadline"
            description="Default value is 7 days from today"
            placeholder="Pick date"
            error="Please select renewal deadline"
            required={true}
            valueFormat="DD-MMM-YYYY"
            my="lg"
            maw={400}
            {...form.getInputProps('dueDate')}
          />

          <TextInput
            py="lg"
            placeholder="Enter Subject Line"
            description="{{ childName }} is a placeholder and will be replaced with actual child name when email is sent"
            label="Subject"
            required={true}
            {...form.getInputProps('subject')}
          />

          <TextInput
            py="lg"
            placeholder="Enter Email Header"
            label="Email Header"
            description="Text shown at the top of email"
            required={true}
            {...form.getInputProps('header')}
          />
          <Textarea
            py="lg"
            placeholder="Email Content"
            label="Email Content"
            required={true}
            autosize
            minRows={4}
            {...form.getInputProps('content')}
          />

          <FileInput
            py="lg"
            placeholder="Select Excel File"
            label="Excel File With Buslist Sheet"
            value={emailFile}
            required={true}
            accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            onChange={setEmailFile}
          ></FileInput>
          <Center>
            <Button
              disabled={isLoading}
              type="submit"
              leftIcon={<IconFileUpload size="20" />}
            >
              Process Renewals
            </Button>
          </Center>
        </form>
      </Stack>
    </>
  );
};

export default RenewalForm;
