import { ActionIcon, Alert, Table } from '@mantine/core';
import { modals } from '@mantine/modals';
import { Parent } from '@prisma/client';
import { IconAlertCircle, IconBrandWhatsapp } from '@tabler/icons-react';
import React from 'react';

function openWhatsapp(phone: string | null, message: string) {
  if (phone) {
    modals.open({
      title: 'Error',
      children: (
        <Alert
          icon={<IconAlertCircle size="20" />}
          title="There is no phone number stored for this parent."
          color="red"
        >
          Please send the reminder message manually.
        </Alert>
      ),
    });
  } else {
    return window.open(`https://wa.me/65${phone}?text=${message}`);
  }
}

const useWhatsappReminder = (
  messageTemplate: (parent: Parent, additionalData: string) => string
) => {
  const sendWhatsappReminder = (parents: Parent[], messageData: string) => {
    if (parents.length === 1) {
      openWhatsapp(parents[0].phone, messageTemplate(parents[0], messageData));
    } else {
      modals.open({
        title: 'Select Parent',
        children: (
          <>
            <Table withBorder withColumnBorders highlightOnHover>
              <thead>
                <tr>
                  <th>Type</th>
                  <th>Name</th>
                  <th>Mobile</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {parents.map((parent, index) => {
                  return (
                    <tr key={index}>
                      <td>{parent.type}</td>
                      <td>{parent.name}</td>
                      <td>{parent.phone}</td>
                      <td
                        onClick={() => {
                          modals.closeAll();
                          openWhatsapp(
                            parent.phone,
                            messageTemplate(parent, messageData)
                          );
                        }}
                      >
                        <ActionIcon>
                          <IconBrandWhatsapp />
                        </ActionIcon>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </>
        ),
      });
    }
  };

  return { sendWhatsappReminder };
};

export default useWhatsappReminder;
