import { Table, Text } from '@mantine/core';
import { InvoiceStatus, RenewalStatus } from '@prisma/client';
import dayjs from 'dayjs';
import React from 'react';

const EventInfoTable = ({
  statuses,
}: {
  statuses: InvoiceStatus[] | RenewalStatus[];
}) => {
  return (
    <>
      <Text fw={700} fz={'lg'}>
        Events
      </Text>
      <Table striped withBorder withColumnBorders captionSide="top">
        <thead>
          <tr>
            <th>Date Time</th>
            <th>Event</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>
          {statuses.map(
            (event: InvoiceStatus | RenewalStatus, index: number) => {
              return (
                <tr key={index}>
                  <td>
                    {dayjs(event.createdAt).format('YYYY-MM-DD HH:mm:ss')}
                  </td>
                  <td>{event.status}</td>
                  <td>{event.details}</td>
                </tr>
              );
            }
          )}
        </tbody>
      </Table>
    </>
  );
};

export default EventInfoTable;
