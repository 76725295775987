import { Table, Text } from '@mantine/core';
import { Parent } from '@prisma/client';
import React from 'react';

const ParentInfoTable = ({ parents }: { parents: Parent[] }) => {
  return (
    <>
      <Text fw={700} fz={'lg'}>
        Parent Info
      </Text>
      <Table
        withBorder
        withColumnBorders
        highlightOnHover
        sx={{ cursor: 'pointer' }}
      >
        <thead>
          <tr>
            <th>Type</th>
            <th>Name</th>
            <th>Email</th>
            <th>Mobile</th>
          </tr>
        </thead>
        <tbody>
          {parents.map((parent, index) => {
            return (
              <tr key={index}>
                <td>{parent.type}</td>
                <td>{parent.name}</td>
                <td>{parent.email}</td>
                <td>{parent.phone}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </>
  );
};

export default ParentInfoTable;
