import { ActionIcon, Flex, Grid, Tooltip } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { Parent } from '@prisma/client';
import { IconBrandWhatsapp, IconRefresh, IconX } from '@tabler/icons-react';
import { useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';
import {
  MRT_Cell,
  MRT_ColumnDef,
  MRT_Row,
  MRT_ShowHideColumnsButton,
  MRT_ToggleFiltersButton,
  MRT_ToggleGlobalFilterButton,
  MantineReactTable,
  useMantineReactTable,
} from 'mantine-react-table';
import React, { useEffect, useMemo, useState } from 'react';
import { RenewalLogDetail } from '../../../types/lib';
import apiHelper from '../../utils/api';
import EventInfoTable from '../common/components/EventInfoTable';
import ParentInfoTable from '../common/components/ParentInfoTable';
import useWhatsappReminder from '../common/hooks/useWhatsappReminder.hook';
import AddressTable from './AddressTable';

const RenewalLogDetailTable = ({ renewals }) => {
  const [data, setData] = useState(renewals);
  const queryClient = useQueryClient();
  const { sendWhatsappReminder } = useWhatsappReminder(messageTemplate);
  useEffect(() => {
    setData(...[Object.values(renewals)]);
  }, [renewals]);

  const handleSaveCell = async (
    cell: MRT_Cell<RenewalLogDetail>,
    value: string
  ) => {
    try {
      if (!cell.id.endsWith('dateOfBirth'))
        throw Error('Only Child Date of Birth can be edited');
      const date = dayjs(value, 'DD-MMM-YYYY').toDate();
      await apiHelper.updateChildDob(cell.row.original.child.id, date);
    } catch (err) {
      notifications.show({
        title: 'An error has ocurred!',
        message: err.message ?? 'Please try again later',
        color: 'red',
        icon: <IconX />,
        autoClose: false,
      });
    }
  };

  function displayDetail(row: MRT_Row<RenewalLogDetail>) {
    return (
      <Grid>
        <Grid.Col span={5}>
          <ParentInfoTable parents={row.original.parents} />
        </Grid.Col>
        <Grid.Col span={7}>
          <EventInfoTable statuses={row.original.statuses} />
        </Grid.Col>
        <Grid.Col>
          <AddressTable
            pickUpAddress={row.original.pickUpAddress}
            dropOffAddress={row.original.dropOffAddress}
          />
        </Grid.Col>
      </Grid>
    );
  }

  const columns = useMemo<MRT_ColumnDef<RenewalLogDetail>[]>(
    () => [
      {
        accessorKey: 'child.rsId',
        header: 'Child ID',
      },
      {
        accessorKey: 'currentStatus',
        header: 'Status',
        filterVariant: 'multi-select',
      },
      {
        accessorKey: 'child.name',
        header: 'Child Name',
        filterVariant: 'text',
      },
      {
        accessorFn: (originalRow) =>
          dayjs(originalRow.child.dateOfBirth).format('DD-MMM-YYYY'),
        id: 'child.dateOfBirth',
        header: 'Child DOB',
        filterVariant: 'date',
      },
      {
        accessorKey: 'fare',
        header: 'Fare',
        filterVariant: 'range-slider',
        Cell: ({ cell }) => (
          <span>${cell.getValue<number>().toLocaleString()}</span>
        ),
      },
      {
        accessorKey: 'serviceRequirement',
        header: 'Service Requirement',
        filterVariant: 'multi-select',
      },
      {
        accessorFn: (row) => {
          if (row.pickUpAddress === null) return 'NA';
          else
            return `${row.pickUpAddress.blkNum} ${row.pickUpAddress.streetName} ${row.pickUpAddress.postalCode}`;
        },
        id: 'pickUpAddress',
        header: 'Pick-up Address',
        filterVariant: 'text',
      },
      {
        accessorFn: (row) => {
          if (row.dropOffAddress === null) return 'NA';
          else
            return `${row.dropOffAddress.blkNum} ${row.dropOffAddress.streetName} ${row.dropOffAddress.postalCode}`;
        },
        id: 'dropOffAddress',
        header: 'Drop-off Address',
        filterVariant: 'text',
      },
    ],
    []
  );

  const table = useMantineReactTable({
    columns,
    data,
    enableEditing: true,
    editDisplayMode: 'cell',
    mantineEditTextInputProps: ({ cell }) => ({
      onBlur: (event) => {
        handleSaveCell(cell, event.target.value);
      },
    }),
    enableFullScreenToggle: false,
    enableDensityToggle: false,
    enableFacetedValues: true,
    enableRowActions: true,
    renderRowActions: ({ row }) => (
      <Flex>
        <Tooltip label="Send Reminder Whatsapp">
          <ActionIcon
            onClick={() =>
              sendWhatsappReminder(row.original.parents, row.original.id)
            }
          >
            <IconBrandWhatsapp />
          </ActionIcon>
        </Tooltip>
      </Flex>
    ),
    initialState: {
      density: 'xs',
      showColumnFilters: true,
    },
    enableClickToCopy: true,
    renderDetailPanel: ({ row }) => displayDetail(row),
    enableRowSelection: true,
    getRowId: (originalRow) => originalRow.id,
    renderToolbarInternalActions: ({ table }) => (
      <Flex gap="xs" align="center">
        <Tooltip label="Refresh Data">
          <ActionIcon
            onClick={() =>
              queryClient.invalidateQueries({ queryKey: ['renewals'] })
            }
          >
            <IconRefresh />
          </ActionIcon>
        </Tooltip>
        <MRT_ToggleGlobalFilterButton table={table} />
        <MRT_ToggleFiltersButton table={table} />
        <MRT_ShowHideColumnsButton table={table} />
      </Flex>
    ),
  });

  return <MantineReactTable table={table} />;
};

export default RenewalLogDetailTable;

function messageTemplate(parent: Parent, renewalId: string) {
  return `Hi ${parent.name}, please be reminded to indicate whether your child requires school bus service for the upcoming school year by filling in the form at this link https://parents.rstransport.com.sg/renewal/${renewalId}. Thank you!`;
}
