import {
  Button,
  Center,
  Container,
  Group,
  Image,
  LoadingOverlay,
  Paper,
  PasswordInput,
  Text,
  TextInput,
  Title,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { notifications } from '@mantine/notifications';
import { IconEyeCheck, IconEyeOff, IconX } from '@tabler/icons-react';
import React, { useEffect, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useNavigate } from 'react-router-dom';
import { auth, login } from '../../utils/auth';

export function Login() {
  const [manualLoading, setLoading] = useState(false);
  const [user] = useAuthState(auth);
  const navigate = useNavigate();

  useEffect(() => {
    if (user) navigate('/portal');
  });

  const form = useForm({
    initialValues: {
      email: '',
      password: '',
    },
    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Invalid email'),
    },
    validateInputOnBlur: true,
  });

  const handleLogin = async () => {
    setLoading(true);
    try {
      await login(form.values.email, form.values.password);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      let message = 'An error has occurred. Please try again later.';
      if (
        err instanceof Error &&
        (err.message === 'Firebase: Error (auth/wrong-password).' ||
          err.message === 'Firebase: Error (auth/wrong-password).')
      ) {
        message = 'Incorrect email/password. Please try again.';
      }
      notifications.show({
        message: message,
        color: 'red',
        icon: <IconX />,
      });
    }
  };

  const handleError = (errors: typeof form.errors) => {
    if (errors.email) {
      notifications.show({
        message: 'Please provide a valid email',
        color: 'red',
      });
    }
  };

  return (
    <>
      <LoadingOverlay visible={manualLoading} overlayBlur={2} />
      <Container size={420} my={120}>
        <form onSubmit={form.onSubmit(handleLogin, handleError)}>
          <Title
            align="center"
            sx={(theme) => ({
              fontFamily: `Greycliff CF, ${theme.fontFamily}`,
              fontWeight: 900,
            })}
          >
            Admin Login
          </Title>

          <Center>
            <Image mt="xl" maw={120} src="logo.png" alt="RS Transport Logo" />
          </Center>
          <Text color="dimmed" size="sm" align="center" mt="lg">
            If you are not authorised to use this system, please do not attempt
            to login.
          </Text>
          <Paper withBorder shadow="md" p={30} mt={30} radius="md">
            <TextInput
              label="Email"
              placeholder="you@mail.com"
              required
              {...form.getInputProps('email')}
            />
            <PasswordInput
              placeholder="Password"
              label="Password"
              required
              visibilityToggleIcon={({ reveal }) =>
                reveal ? <IconEyeOff size={20} /> : <IconEyeCheck size={20} />
              }
              {...form.getInputProps('password')}
            />
            <Group position="apart" mt="lg"></Group>
            <Button fullWidth mt="xl" type="submit">
              Sign in
            </Button>
          </Paper>
        </form>
      </Container>
    </>
  );
}
