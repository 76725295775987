import { InvoiceGroup } from '@prisma/client';
import dayjs from 'dayjs';
import {
  MantineReactTable,
  useMantineReactTable,
  type MRT_ColumnDef,
} from 'mantine-react-table';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const InvoiceLogsTable = (invoiceGroups: InvoiceGroup[]) => {
  const [data, setData] = useState(invoiceGroups);
  const navigate = useNavigate();

  useEffect(() => {
    setData(Object.values(invoiceGroups));
  }, [invoiceGroups]);

  const columns = useMemo<MRT_ColumnDef<InvoiceGroup>[]>(
    () => [
      {
        accessorFn: (originalRow) =>
          dayjs(originalRow.invoiceDate).format('DD-MMM-YYYY'),
        id: 'invoiceDate',
        header: 'Invoice Date',
        filterVariant: 'date',
      },
      {
        accessorFn: (originalRow) =>
          dayjs(originalRow.invoiceDueDate).format('DD-MMM-YYYY'),
        id: 'invoiceDueDate',
        header: 'Invoice Due Date',
        filterVariant: 'date',
      },
      {
        accessorKey: 'school',
        header: 'School',
        filterVariant: 'multi-select',
      },
      {
        accessorKey: 'type',
        header: 'Invoice Type',
        filterVariant: 'multi-select',
      },
    ],
    []
  );

  const table = useMantineReactTable({
    columns,
    data,
    enableFullScreenToggle: false,
    enableDensityToggle: false,
    enableFacetedValues: true,
    initialState: {
      density: 'xs',
      showColumnFilters: true,
    },
    mantineTableBodyRowProps: ({ row }) => ({
      onClick: () => {
        navigate(`${row.original.id}`);
      },
      sx: {
        cursor: 'pointer', //you might want to change the cursor too when adding an onClick
      },
    }),
  });

  return <MantineReactTable table={table} />;
};

export default InvoiceLogsTable;
