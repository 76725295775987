import { LoadingOverlay } from '@mantine/core';
import React, { createContext, useState } from 'react';

export const LoaderContext = createContext({
  isLoading: false,
  showLoader: () => {
    // show loader
  },
  hideLoader: () => {
    // hide loader
  },
});

export const LoaderProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);

  const showLoader = () => setIsLoading(true);
  const hideLoader = () => setIsLoading(false);

  const contextValues = {
    isLoading,
    showLoader,
    hideLoader,
  };

  return (
    <LoaderContext.Provider value={contextValues}>
      <LoadingOverlay visible={isLoading} overlayBlur={2} />
      {children}
    </LoaderContext.Provider>
  );
};
