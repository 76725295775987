import { ActionIcon, Button, Flex, Grid, Tooltip } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { Parent } from '@prisma/client';
import {
  IconBrandWhatsapp,
  IconDownload,
  IconRefresh,
  IconX,
} from '@tabler/icons-react';
import { useQueryClient } from '@tanstack/react-query';
import {
  MRT_ColumnDef,
  MRT_Row,
  MRT_ShowHideColumnsButton,
  MRT_ToggleFiltersButton,
  MRT_ToggleGlobalFilterButton,
  MantineReactTable,
  useMantineReactTable,
} from 'mantine-react-table';
import React, { useEffect, useMemo, useState } from 'react';
import { InvoiceLogDetail } from '../../../types/lib';
import apiHelper from '../../utils/api';
import EventInfoTable from '../common/components/EventInfoTable';
import ParentInfoTable from '../common/components/ParentInfoTable';
import useWhatsappReminder from '../common/hooks/useWhatsappReminder.hook';

const InvoiceLogTable = ({ invoices, logId }) => {
  const [data, setData] = useState(invoices);
  const queryClient = useQueryClient();
  const { sendWhatsappReminder } = useWhatsappReminder(messageTemplate);
  useEffect(() => {
    setData(...[Object.values(invoices)]);
  }, [invoices]);

  // const markSingleInvoicePaid = useMutation({
  //   mutationFn: async (invoiceId: string) => {
  //     notifications.show({
  //       id: 'loading',
  //       loading: true,
  //       message: 'Marking Invoice as Paid',
  //     });
  //     return await apiHelper.markSingleInvoiceAsPaid(invoiceId);
  //   },
  //   onSuccess: () => {
  //     queryClient.invalidateQueries({ queryKey: ['invoices'] });
  //     notifications.show({
  //       message: 'The invoice has been marked as paid.',
  //       icon: <IconCheck />,
  //       autoClose: 5000,
  //       withCloseButton: true,
  //     });
  //   },
  //   onError: (err) => {
  //     notifications.show({
  //       title: 'An error has ocurred!',
  //       message: err.message,
  //       color: 'red',
  //       icon: <IconX />,
  //     });
  //   },
  //   onSettled: () => {
  //     notifications.hide('loading');
  //   },
  // });

  const downloadInvoices = async () => {
    notifications.show({
      id: 'loading',
      loading: true,
      message: 'Downloading Invoices',
      autoClose: false,
    });

    try {
      const data = await apiHelper.downloadInvoices(logId ?? -1);
      const url = window.URL.createObjectURL(data);
      const a = document.createElement('a');
      document.body.appendChild(a);
      a.href = url;
      a.download = 'Invoices.zip';
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (err) {
      notifications.show({
        title: 'An error has ocurred!',
        message: err.message,
        color: 'red',
        icon: <IconX />,
      });
    } finally {
      notifications.hide('loading');
    }
  };

  const columns = useMemo<MRT_ColumnDef<InvoiceLogDetail>[]>(
    () => [
      {
        accessorKey: 'child.rsId',
        header: 'Child ID',
      },
      {
        accessorKey: 'currentStatus',
        header: 'Status',
        filterVariant: 'multi-select',
      },
      {
        accessorKey: 'child.name',
        header: 'Child Name',
        filterVariant: 'text',
      },
      {
        accessorKey: 'fare',
        header: 'Fare',
        filterVariant: 'range-slider',
        Cell: ({ cell }) => (
          <span>${cell.getValue<number>().toLocaleString()}</span>
        ),
      },
    ],
    []
  );

  const table = useMantineReactTable({
    columns,
    data,
    enableFullScreenToggle: false,
    enableDensityToggle: false,
    enableFacetedValues: true,
    enableRowActions: true,
    renderRowActions: ({ row }) => (
      <Flex>
        <Tooltip label="Send Reminder Whatsapp">
          <ActionIcon
            // disabled={row.original.currentStatus === 'PAID'}
            onClick={() =>
              sendWhatsappReminder(
                row.original.parents,
                row.original.fare.toString()
              )
            }
          >
            <IconBrandWhatsapp />
          </ActionIcon>
        </Tooltip>
        {/* <Tooltip label="Mark as Paid">
          <ActionIcon
            disabled={row.original.currentStatus === 'PAID'}
            onClick={() => markSingleInvoicePaid.mutate(row.original.id)}
          >
            <IconReportMoney />
          </ActionIcon>
        </Tooltip> */}
      </Flex>
    ),
    initialState: {
      density: 'xs',
      showColumnFilters: true,
    },
    enableClickToCopy: true,
    renderDetailPanel: ({ row }) => displayDetail(row),
    enableRowSelection: true,
    getRowId: (originalRow) => originalRow.id,
    renderTopToolbarCustomActions: () => (
      <Flex gap={'md'} align={'center'}>
        <Button leftIcon={<IconDownload />} onClick={() => downloadInvoices()}>
          Download Invoices
        </Button>
        {/* <Button
          leftIcon={<IconReportMoney />}
          disabled={!table.getIsSomeRowsSelected()}
          onClick={() => {
            markInvoicesAsPaid.mutate(
              Object.keys(table.getState().rowSelection)
            );
          }}
        >
          Mark as Paid
        </Button> */}
      </Flex>
    ),
    renderToolbarInternalActions: ({ table }) => (
      <Flex gap="xs" align="center">
        <Tooltip label="Refresh Data">
          <ActionIcon
            onClick={() =>
              queryClient.invalidateQueries({ queryKey: ['invoices'] })
            }
          >
            <IconRefresh />
          </ActionIcon>
        </Tooltip>
        <MRT_ToggleGlobalFilterButton table={table} />
        <MRT_ToggleFiltersButton table={table} />
        <MRT_ShowHideColumnsButton table={table} />
      </Flex>
    ),
  });

  return <MantineReactTable table={table} />;
};

export default InvoiceLogTable;

function messageTemplate(parent: Parent, fare: string) {
  return `Hi ${parent.name}, your school bus invoice is attached. Please make payment of $${fare} within 7 days. Thank you!`;
}

function displayDetail(row: MRT_Row<InvoiceLogDetail>) {
  return (
    <Grid>
      <Grid.Col span={5}>
        <ParentInfoTable parents={row.original.parents} />
      </Grid.Col>
      <Grid.Col span={7}>
        <EventInfoTable statuses={row.original.statuses} />
      </Grid.Col>
    </Grid>
  );
}

// async function sendReminder(row: InvoiceLogDetail) {
//   try {
//     console.log(row);
//     await apiHelper.sendSingleReminderEmail(row.id);
//   } catch (err) {
//     console.error(err);
//     notifications.show({
//       title: 'An error has ocurred!',
//       message: err.message,
//       color: 'red',
//       icon: <IconX />,
//     });
//   }
// }
