import { Alert, LoadingOverlay, Stack, Title } from '@mantine/core';
import { IconAlertCircle } from '@tabler/icons-react';
import { useQuery } from '@tanstack/react-query';
import React from 'react';
import apiHelper from '../../utils/api';
import RenewalLogsTable from './RenewalLogsTable';

const RenewalLogsOverviewPage = () => {
  const { isPending, isError, data, error } = useQuery({
    queryKey: ['renewalGroups'],
    queryFn: () => apiHelper.getRenewalGroups(),
  });

  return (
    <>
      <Title order={1}>Renewal Logs</Title>
      <Stack pos={'relative'}>
        {isError && (
          <Alert
            icon={<IconAlertCircle size="20" />}
            title="An error has ocurred retrieving data. Please refresh the page to try again."
            color="red"
          >
            {error.message}
          </Alert>
        )}

        <LoadingOverlay visible={isPending} overlayBlur={2} />
        <RenewalLogsTable {...(data ?? [])} />
      </Stack>
    </>
  );
};

export default RenewalLogsOverviewPage;
