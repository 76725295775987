import { Table, Text } from '@mantine/core';
import { Address } from '@prisma/client';
import React from 'react';

const AddressTable = ({
  pickUpAddress,
  dropOffAddress,
}: {
  pickUpAddress: Address;
  dropOffAddress: Address;
}) => {
  return (
    <>
      <Text fw={700} fz={'lg'}>
        Address
      </Text>
      <Table withBorder withColumnBorders highlightOnHover>
        <thead>
          <tr>
            <th>Type</th>
            <th>Blk</th>
            <th>Street</th>
            <th>Condo</th>
            <th>Postal Code</th>
          </tr>
        </thead>
        <tbody>
          {pickUpAddress && (
            <tr>
              <td>Pick-Up Address</td>
              <td>{pickUpAddress.blkNum}</td>
              <td>{pickUpAddress.streetName}</td>
              <td>{pickUpAddress.buildingName}</td>
              <td>{pickUpAddress.postalCode}</td>
            </tr>
          )}
          {dropOffAddress && (
            <tr>
              <td>Drop-Off Address</td>
              <td>{dropOffAddress.blkNum}</td>
              <td>{dropOffAddress.streetName}</td>
              <td>{dropOffAddress.buildingName}</td>
              <td>{dropOffAddress.postalCode}</td>
            </tr>
          )}
        </tbody>
      </Table>
    </>
  );
};

export default AddressTable;
