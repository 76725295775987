import { ActionIcon, Flex, Tooltip } from '@mantine/core';
import { RenewalGroup } from '@prisma/client';
import { IconEye, IconTrash } from '@tabler/icons-react';
import dayjs from 'dayjs';
import {
  MantineReactTable,
  useMantineReactTable,
  type MRT_ColumnDef,
} from 'mantine-react-table';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const RenewalLogsTable = (renewalGroups: RenewalGroup[]) => {
  const [data, setData] = useState(renewalGroups);
  const navigate = useNavigate();

  useEffect(() => {
    setData(Object.values(renewalGroups));
  }, [renewalGroups]);

  function deleteRenewalGroup(renewalGroupId: number) {
    console.log(renewalGroupId);
  }

  const columns = useMemo<MRT_ColumnDef<RenewalGroup>[]>(
    () => [
      {
        accessorFn: (originalRow) =>
          dayjs(originalRow.createdAt).add(1, 'year').format('YYYY'),
        id: 'createdAt',
        header: 'For School Year',
        filterVariant: 'date',
      },
      {
        accessorKey: 'school',
        header: 'School',
        filterVariant: 'multi-select',
      },
      {
        accessorFn: (originalRow) =>
          dayjs(originalRow.renewalDeadline).format('DD-MMM-YYYY'),
        id: 'renewalDeadline',
        header: 'Renewal Deadline',
        filterVariant: 'date',
      },
      {
        accessorFn: (originalRow) => (originalRow.emailSent ? 'Yes' : 'No'),
        id: 'emailSent',
        header: 'Email Sent?',
        filterVariant: 'select',
      },
    ],
    []
  );

  const table = useMantineReactTable({
    columns,
    data,
    enableFullScreenToggle: false,
    enableDensityToggle: false,
    enableFacetedValues: true,
    enableRowActions: true,
    renderRowActions: ({ row }) => (
      <Flex>
        <Tooltip label="View Renewal Group">
          <ActionIcon onClick={() => navigate(`${row.original.id}`)}>
            <IconEye />
          </ActionIcon>
        </Tooltip>
        <Tooltip label="Delete Renewal Group">
          <ActionIcon onClick={() => deleteRenewalGroup(row.original.id)}>
            <IconTrash />
          </ActionIcon>
        </Tooltip>
      </Flex>
    ),
    positionActionsColumn: 'last',
    initialState: {
      density: 'xs',
      showColumnFilters: true,
    },
  });

  return <MantineReactTable table={table} />;
};

export default RenewalLogsTable;
