import {
  Alert,
  Button,
  List,
  LoadingOverlay,
  Stack,
  Title,
} from '@mantine/core';
import { notifications } from '@mantine/notifications';
import {
  IconAlertCircle,
  IconCheck,
  IconMail,
  IconX,
} from '@tabler/icons-react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import apiHelper from '../../utils/api';
import RenewalLogDetailTable from './RenewalLogDetailTable';

const RenewalLogDetailPage = () => {
  const { logId } = useParams();
  const [isLoading, setLoading] = useState(false);
  const queryClient = useQueryClient();

  const { isPending, isError, data, error } = useQuery({
    queryKey: ['renewals'],
    queryFn: () => apiHelper.getRenewalsByRenewalGroup(logId ?? ''),
  });

  const handleSendEmails = async () => {
    setLoading(true);
    try {
      if (!logId)
        throw new Error(
          'No renewal group ID detected, please refresh the page'
        );
      await apiHelper.sendRenewalEmails(logId);
      notifications.show({
        title: 'Renewal emails have been sent!',
        message:
          'To monitor the status of emails, please refresh the table below using the refresh icon in the top left corner of the table.',
        icon: <IconCheck />,
        autoClose: true,
      });
      queryClient.invalidateQueries({ queryKey: ['renewals'] });
    } catch (err) {
      notifications.show({
        title: 'An error has ocurred!',
        message: err.message ?? 'Please try again later',
        color: 'red',
        icon: <IconX />,
        autoClose: false,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Title order={1}>Renewal Log Detail</Title>
      <List fz="sm">
        <List.Item>
          This page provides an overview of the status of invoices sent via
          email. Hover over the numbers to see an explanation of each status.
        </List.Item>
        <List.Item>
          Please allow up to 15 minutes for the invoice emails to be sent out.
          You may click the refresh button at the top right of the table to get
          the latest status.
        </List.Item>
        <List.Item>
          If you wish to send a WhatsApp message to the parents, you may click
          the WhatsApp icon in the actions column of the table. This will open
          the WhatsApp web interface with a pre-filled message to the parent.
        </List.Item>
      </List>
      <Stack pos={'relative'}>
        {!data?.emailSent && (
          <Alert
            icon={<IconAlertCircle size="20" />}
            title="Please take note that emails have not been sent to parents."
          >
            <Stack>
              Please verify the information below before proceeding to send the
              emails.
              <Button
                onClick={() => handleSendEmails()}
                loading={isLoading}
                leftIcon={<IconMail size="20" />}
              >
                Send Emails
              </Button>
            </Stack>
          </Alert>
        )}

        {isError && (
          <Alert
            icon={<IconAlertCircle size="20" />}
            title="An error has ocurred retrieving data. Please refresh the page to try again."
            color="red"
          >
            {error.message}
          </Alert>
        )}

        <LoadingOverlay visible={isPending} overlayBlur={2} />

        <RenewalLogDetailTable renewals={data?.renewals ?? []} />
      </Stack>
    </>
  );
};

export default RenewalLogDetailPage;
